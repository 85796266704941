import { useEffect, useState } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { makeStyles, Button, Fade, IconButton } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import CloseIcon from "@material-ui/icons/Close";
import {
  affiliateLink,
  facebookLink,
  linkedinLink,
  textMessageLink,
  twitterLink,
  whatsappLink,
} from "../lib";
import { BOTTOM_BAR_HEIGHT, Contact, paths } from "../types";
import { useSelector } from "react-redux";
import { contactActions } from "../state";
import { EZShareSVG } from "../assets/EZShareSVG";
import { RootState, useAppDispatch } from "../types/state";
import { SkeletonLoader } from "../components";
import { FacebookSVG } from "../assets/FacebookSVG";
import { TwitterSVG } from "../assets/TwitterSVG";
import { LinkedinSVG } from "../assets/LinkedinSVG";
import { WhatsappSVG } from "../assets/WhatsappSVG";
import { LightbulbSVG } from "../assets/LightbulbSVG";

export function VolunteerEZShare({ location }) {
  const classes = styles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { a } = queryString.parse(location.search);
  const affiliate: string = a ? (a as string) : "";
  const { campaignInfoFetched, campaignId, orgName, webpageLink } = useSelector(
    (state: RootState) => state.campaign,
  );
  const contacts = useSelector((state: RootState) => state.contacts.contacts);
  const [phoneContacts, setPhoneContacts] = useState<Contact[]>([]);
  const [hasPhoneContacts, setHasPhoneContacts] = useState(false);
  const link = affiliateLink(webpageLink, affiliate);
  const [loading, setLoading] = useState(true);
  const [contactsLoading, setContactsLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      if (!campaignId) return;
      const success = await dispatch(contactActions.fetchContacts());
      if (success) setContactsLoading(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  useEffect(() => {
    if (!contactsLoading && campaignInfoFetched) setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignInfoFetched, contactsLoading]);

  useEffect(() => {
    if (Array.isArray(contacts)) {
      const _PC = contacts.filter(({ phone }) => Boolean(phone));
      setPhoneContacts(_PC);
      setHasPhoneContacts(Boolean(_PC.length));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <EZShareSVG />
        <h1 className={classes.header}>
          Welcome to your <span className={classes.ezSharing}>EZ-Sharing</span>{" "}
          space
        </h1>
        <SkeletonLoader show={loading} />
        {!loading && hasPhoneContacts && (
          <div className={classes.subheader}>
            Tap the TEXT button to launch your messaging app with a prewritten
            message containing a link to your page.
          </div>
        )}
        {!loading && !hasPhoneContacts && (
          <div className={classes.subheader}>
            There are no fundraising contacts with phone numbers. Visit the{" "}
            <span
              className={classes.contactsLink}
              onClick={() => history.push(paths.CONTACTS)}
            >
              Contacts
            </span>{" "}
            section and add phone numbers for easy text message fundraising.
          </div>
        )}

        {!loading &&
          phoneContacts.map((contact, index) => (
            <Row key={index} contact={contact} link={link} orgName={orgName} />
          ))}

        {!loading && !hasPhoneContacts && (
          <div>
            <Button
              href={textMessageLink(link, orgName, true)}
              className={classes.noContactsButton}
              color="primary"
              startIcon={<SmsOutlinedIcon />}
            >
              Send a text
            </Button>
            <Tooltip text="Click to share a link to your page via text message" />
          </div>
        )}
      </div>

      <div className={classes.socialMediaBar}>
        <div className={classes.smInstructions}>
          Tap on an icon to share your campaign on social media
        </div>
        <div className={classes.socialMediaLinks}>
          <IconButton
            size="small"
            href={facebookLink(link)}
            target="_blank"
            disableFocusRipple
            disableRipple
            classes={{ root: classes.iconButton }}
          >
            <FacebookSVG />
          </IconButton>
          <IconButton
            size="small"
            href={twitterLink(link, orgName, true)}
            target="_blank"
            disableFocusRipple
            disableRipple
            classes={{ root: classes.iconButton }}
          >
            <TwitterSVG />
          </IconButton>
          <IconButton
            size="small"
            href={linkedinLink(link)}
            target="_blank"
            disableFocusRipple
            disableRipple
            classes={{ root: classes.iconButton }}
          >
            <LinkedinSVG />
          </IconButton>
          <IconButton
            size="small"
            href={whatsappLink(link, orgName, true)}
            target="_blank"
            disableFocusRipple
            disableRipple
            classes={{ root: classes.iconButton }}
          >
            <WhatsappSVG />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

type RowProps = {
  contact: Contact;
  orgName: string;
  link: string;
};
function Row({ contact, orgName, link }: RowProps) {
  const { name, phone, alreadySupported } = contact;
  const [redirectedToTextApp, setRedirectedToTextApp] = useState(false);
  const showTxtButton = !redirectedToTextApp && !alreadySupported;
  const classes = styles();

  return (
    <div className={classes.row}>
      <div className={classes.nameAndNum}>
        <div className={classes.name}>{name}</div>
        <div className={classes.number}>{phone}</div>
      </div>

      {showTxtButton && (
        <Button
          color="primary"
          variant="text"
          size="small"
          startIcon={<SmsOutlinedIcon />}
          className={classes.button}
          href={textMessageLink(link, orgName, true, phone)}
          onClick={() => setRedirectedToTextApp(true)}
        >
          TEXT
        </Button>
      )}
      {!showTxtButton && (
        <div className={classes.checkContainer}>
          <CheckCircleIcon className={classes.checkIcon} />
          {redirectedToTextApp ? "Texted" : "Already supported"}
        </div>
      )}
    </div>
  );
}

function Tooltip({ text }: { text: string }) {
  const classes = styles();
  const [dismissed, setDismissed] = useState(false);

  if (dismissed) return <></>;
  return (
    <Fade in={true} timeout={500} style={{ transitionDelay: "1000ms" }}>
      <div className={classes.tooltipContainer}>
        <div className={classes.tooltip}>
          <div className={classes.lightbulb}>
            <LightbulbSVG />
          </div>
          <div className={classes.ttText}>{text}</div>
          <IconButton
            size="small"
            className={classes.ttIconButton}
            onClick={() => setDismissed(true)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </Fade>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 24,
    backgroundColor: "#FFFFFF",
  },
  container: {
    width: 500,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 16,
  },
  header: {
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "32px",
    textAlign: "center",
    paddingTop: 32,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 32,
      paddingRight: 32,
    },
  },
  ezSharing: {
    textWrap: "nowrap",
  },

  subheader: {
    fontSize: 16,
    letterSpacing: 0.12,
    lineHeight: "24px",
    paddingBottom: 32,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "100%",
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: "1px solid #EAEBF3",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  nameAndNum: {
    paddingRight: 12,
    overflow: "hidden",
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    lineHeight: "22px",
    marginBottom: 4,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  number: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  button: {
    width: 68,
  },
  checkContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    letterSpacing: 0.15,
    textWrap: "nowrap",
  },
  checkIcon: {
    color: "#1AC846",
    marginRight: 8,
  },
  socialMediaBar: {
    width: "100%",
    padding: 16,
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "sticky",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      bottom: BOTTOM_BAR_HEIGHT,
    },
  },
  smInstructions: {
    fontSize: 16,
    letterSpacing: 0.12,
    lineHeight: "24px",
    textAlign: "center",
    paddingBottom: 16,
  },
  socialMediaLinks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 260,
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  noContactsButton: {
    width: 300,
  },
  contactsLink: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  tooltipContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 8,
  },
  tooltip: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    top: 12,
    width: 320,
    backgroundColor: "#1B47AA",
    color: "#FFFFFF",
    padding: "6px 8px 15px 12px",
    borderRadius: 8,
    "&:before": {
      content: '""',
      position: "absolute",
      right: 152,
      top: "-7px",
      borderTop: "none",
      borderRight: "8px solid transparent",
      borderLeft: "8px solid transparent",
      borderBottom: "8px solid #1B47AA",
    },
  },
  ttText: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "20px",
    width: 236,
    marginTop: 12,
  },
  ttIconButton: {
    height: "fit-content",
    color: "#FFFFFF",
  },
  lightbulb: {
    marginTop: 12,
  },
}));
