import { Fragment, useEffect, useState } from "react";
import { makeStyles, IconButton, Button } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRightOutlined";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ManageIcon from "@material-ui/icons/SettingsOutlined";
import classNames from "classnames";
import { GenericObject, ListProps, TeamQueryParams } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import { useSelector } from "react-redux";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { formatMoneyUnPadded } from "../../../lib";
import { getTeamPageUrl } from "../../../state";
import { RootState } from "../../../types/state";
import { ManageTeam } from "./ManageTeam";
import { AddTeamToCampaign } from "./AddTeamToCampaign";
import { ListPagination } from "../../../components/list/ListPagination";
const MIN_WIDTH = 1200;

type Props = {
  setOpenTeamId: React.Dispatch<React.SetStateAction<number | null>>;
  openTeamId: null | number;
  listProps: ListProps<TeamQueryParams>;
};
export function TeamRows({ openTeamId, setOpenTeamId, listProps }: Props) {
  const {
    rows,
    nextPage,
    refreshList,
    paginationProps,
    params,
    setParams,
  } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();

  return (
    <Fragment>
      {isMobile && (
        <div className={classNames(classes.row, classes.mobileHeaderRow)}>
          <div>Team</div>
          <div className={classes.raisedHeader}>Raised</div>
          <div />
        </div>
      )}
      <ListRowsWrapper>
        <ListRows minWidth={MIN_WIDTH}>
          {isDesktop && (
            <ListHeaderRowContainer>
              <div className={classes.row}>
                <SortableColumnHeader
                  columnName="Team ID"
                  fieldName="id"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Team name"
                  fieldName="team_name"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Goal"
                  fieldName="goal"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Raised"
                  fieldName="raised"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Member count"
                  fieldName="memberCount"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Active in this campaign"
                  fieldName="onCampaign"
                  params={params}
                  setParams={setParams}
                />
                <div>View team page</div>
                <div />
              </div>
            </ListHeaderRowContainer>
          )}
          {rows.map((row, index) => {
            const lastRow = index === rows.length - 1;
            return (
              <Row
                key={row.id as number}
                row={row}
                lastRow={lastRow}
                refreshList={refreshList}
                params={params}
                openTeamId={openTeamId}
                setOpenTeamId={setOpenTeamId}
              />
            );
          })}
        </ListRows>
        <ListPagination
          nextPage={nextPage}
          paginationProps={paginationProps}
          label="Teams per page:"
          minWidth={MIN_WIDTH}
        />
      </ListRowsWrapper>
    </Fragment>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
  refreshList: () => any;
  params: TeamQueryParams;
  setOpenTeamId: React.Dispatch<React.SetStateAction<number | null>>;
  openTeamId: null | number;
};
function Row({
  row,
  lastRow,
  refreshList,
  params,
  openTeamId,
  setOpenTeamId,
}: RowProps) {
  const classes = styles();
  const [showDetails, setShowDetails] = useState(false);
  const [showAddToCampaign, setShowAddToCampaign] = useState(false);
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const {
    id,
    team_name,
    goal,
    raised,
    memberCount,
    onCampaign,
    team_slug,
  } = row;
  const teamPage = useSelector((s: RootState) =>
    getTeamPageUrl(s, team_slug as string),
  );

  // when existing Team is added to this campaign we want to open the management automatically
  useEffect(() => {
    if (id === openTeamId && onCampaign) {
      setShowDetails(true);
      setOpenTeamId(null);
    } else if (id === openTeamId && !onCampaign) setOpenTeamId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, openTeamId, onCampaign]);

  return (
    <ListRowContainer lastRow={lastRow}>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>{`#${id}`}</div>
            <div className={classes.name}>{team_name}</div>
            <div>{onCampaign ? formatMoneyUnPadded(goal) : ""}</div>
            <div>{onCampaign ? formatMoneyUnPadded(raised) : ""}</div>
            <div>{onCampaign ? memberCount : ""}</div>
            <div>
              {onCampaign ? (
                <CheckIcon className={classes.active} />
              ) : (
                <CloseIcon className={classes.inactive} />
              )}
            </div>
            <div>
              {onCampaign && (
                <Button
                  variant="text"
                  color="primary"
                  href={teamPage}
                  target="_blank"
                  endIcon={<OpenInNewIcon />}
                >
                  TEAM PAGE
                </Button>
              )}
            </div>
            <div>
              <Button
                variant="text"
                color="primary"
                onClick={() =>
                  onCampaign ? setShowDetails(true) : setShowAddToCampaign(true)
                }
                startIcon={<ManageIcon />}
              >
                MANAGE
              </Button>
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div>
              <div className={classes.nameWrapper}>
                {!params.active_only && onCampaign && (
                  <div className={classes.activeBadge}></div>
                )}
                {!params.active_only && !onCampaign && (
                  <div className={classes.inactiveBadge}></div>
                )}
                <div className={classes.name}>{team_name}</div>
              </div>
              <div>{`#${id}`}</div>
            </div>
            <div className={classes.raised}>
              {onCampaign ? formatMoneyUnPadded(raised) : ""}
            </div>
            <div>
              <IconButton
                size="small"
                onClick={() =>
                  onCampaign ? setShowDetails(true) : setShowAddToCampaign(true)
                }
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
          </Fragment>
        )}
      </div>
      <ManageTeam
        id={id as number}
        onClose={() => setShowDetails(false)}
        isOpen={showDetails}
        refreshList={refreshList}
        teamPage={teamPage}
      />
      {showAddToCampaign && (
        <AddTeamToCampaign
          team_id={id as number}
          teamName={team_name as string}
          onClose={() => setShowAddToCampaign(false)}
          refreshList={refreshList}
          setOpenTeamId={setOpenTeamId}
        />
      )}
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  raisedHeader: {
    textAlign: "right",
  },
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "130px 1fr 144px 186px 146px 136px 140px 128px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 0 6px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 100px 42px",
      padding: "16px 0 16px 16px",
      "& > *": {
        paddingRight: 12,
      },
    },
  },
  mobileHeaderRow: {
    minHeight: "unset",
    padding: "0 0 8px 16px !important",
    fontSize: 12,
    letterSpacing: 0.11,
    color: theme.palette.text.secondary,
  },
  active: {
    color: "#1AC846",
  },
  inactive: {
    color: theme.palette.error.main,
  },
  activeBadge: {
    display: "inline-block",
    backgroundColor: "#1AC846",
    height: 8,
    width: 8,
    minWidth: 8,
    borderRadius: "100%",
    lineHeight: "16px",
    marginRight: 8,
  },
  inactiveBadge: {
    display: "inline-block",
    backgroundColor: theme.palette.error.main,
    height: 8,
    width: 8,
    minWidth: 8,
    borderRadius: "100%",
    lineHeight: "16px",
    marginRight: 8,
  },
  nameWrapper: {
    overflow: "hidden",
    paddingBottom: 6,
    display: "flex",
    alignItems: "center",
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      letterSpacing: 0.1,
    },
  },
  raised: {
    textAlign: "right",
  },
}));
