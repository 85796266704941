import jsonExport from "jsonexport/dist";
import { Base64File, GenericObject } from "../types";
import { IS_RN_WEBVIEW, store } from "../../lib";
import { setToast, toggleLoadingDownload } from "../state";
const ERR_MSG = "There was an error downloading your file";

export async function convertFileToB64(file: File): Promise<Base64File | null> {
  const title = file.name;
  try {
    const b64File: Base64File = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({ title, base64: reader.result });
      reader.onerror = reject;
    });
    return b64File;
  } catch (error) {
    console.error((error as GenericObject)?.message || "Unknown error");
    // TODO: for now showing an `alert`
    alert("There was an error converting your Image/File, please try again.");
    return null;
  }
}

export function downloadCsv(csvObjectArr: GenericObject[], fileName: string) {
  jsonExport(csvObjectArr, (err, csv) => {
    if (err) {
      store.dispatch(setToast(err.message));
    } else {
      if (IS_RN_WEBVIEW) {
        if (!(window as any).ReactNativeWebView) {
          store.dispatch(setToast(ERR_MSG));
        } else {
          (window as any).ReactNativeWebView.postMessage(
            JSON.stringify({
              isDownload: true,
              file: csv,
              fileName,
              fileType: "text/csv",
            }),
          );
        }
      } else {
        const blob = new Blob([csv], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      }
    }
    store.dispatch(toggleLoadingDownload(false));
  });
}

export function downloadPdf(data: any, fileName: string) {
  const blob = new Blob([data], { type: "application/pdf" });

  if (IS_RN_WEBVIEW) {
    const reader = new FileReader();
    reader.onload = () => {
      if (
        typeof reader.result !== "string" ||
        !(window as any).ReactNativeWebView
      ) {
        store.dispatch(setToast(ERR_MSG));
      } else {
        const b64 = reader.result.replace(/^data:.+;base64,/, "");
        (window as any).ReactNativeWebView.postMessage(
          JSON.stringify({
            isDownload: true,
            file: b64,
            fileName,
            fileType: "application/pdf",
            isBase64: true,
          }),
        );
      }
    };
    reader.onerror = () => {
      store.dispatch(setToast(ERR_MSG));
    };
    reader.readAsDataURL(blob);
  } else {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
  store.dispatch(toggleLoadingDownload(false));
}

export function downloadXml(data: any, fileName: string) {
  const blob = new Blob([data], { type: "application/xml" });

  if (IS_RN_WEBVIEW) {
    const reader = new FileReader();
    reader.onload = () => {
      if (
        typeof reader.result !== "string" ||
        !(window as any).ReactNativeWebView
      ) {
        store.dispatch(setToast(ERR_MSG));
      } else {
        const b64 = reader.result.replace(/^data:.+;base64,/, "");
        (window as any).ReactNativeWebView.postMessage(
          JSON.stringify({
            isDownload: true,
            file: b64,
            fileName,
            fileType: "application/xml",
            isBase64: true,
          }),
        );
      }
    };
    reader.onerror = () => {
      store.dispatch(setToast(ERR_MSG));
    };
    reader.readAsDataURL(blob);
  } else {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
  store.dispatch(toggleLoadingDownload(false));
}

export function downloadExcel(data: any, fileName: string) {
  const blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  if (IS_RN_WEBVIEW) {
    const reader = new FileReader();
    reader.onload = () => {
      if (
        typeof reader.result !== "string" ||
        !(window as any).ReactNativeWebView
      ) {
        store.dispatch(setToast(ERR_MSG));
      } else {
        const b64 = reader.result.replace(/^data:.+;base64,/, "");
        (window as any).ReactNativeWebView.postMessage(
          JSON.stringify({
            isDownload: true,
            file: b64,
            fileName,
            fileType:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            isBase64: true,
          }),
        );
      }
    };
    reader.onerror = () => {
      store.dispatch(setToast(ERR_MSG));
    };
    reader.readAsDataURL(blob);
  } else {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
  store.dispatch(toggleLoadingDownload(false));
}
