import { Button, IconButton, makeStyles, Link } from "@material-ui/core";
import QRCode from "qrcode.react";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { VariableHeightDrawer } from "../../../components/ui/VariableHeightDrawer";
import { CopyLink } from "../../../components";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import {
  affiliateLink,
  downloadQRCode,
  facebookLink,
  linkedinLink,
  textMessageLink,
  twitterLink,
  whatsappLink,
} from "../../../lib";
import { useDrawerTransition } from "../../../hooks/ui";
import { ShareCardView } from "../../../types/dashboard";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/state";
import { AFFILIATES } from "../../../../lib";
import { FacebookBlueSmallSVG } from "../../../assets/FacebookBlueSmallSVG";
import { TwitterBlueSmallSVG } from "../../../assets/TwitterBlueSmallSVG";
import { LinkedinBlueSmallSVG } from "../../../assets/LinkedinBlueSmallSVG";
import { WhatsappBlueSmallSVG } from "../../../assets/WhatsappBlueSmallSVG";
import { Fragment } from "react";
import { getVolunteerLabel } from "../../../state";

type Props = {
  onClose: () => void;
  view: ShareCardView;
};
export function LinksDrawer({ onClose: _onClose, view }: Props) {
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const classes = styles();
  const { webpageLink, orgName } = useSelector(
    (state: RootState) => state.campaign,
  );
  const { volunteerLabelLC } = useSelector(getVolunteerLabel);

  const link =
    view === ShareCardView.orgSignUp
      ? `${webpageLink}/volunteers/register`
      : affiliateLink(webpageLink, AFFILIATES.DASHBOARD_SHARING_LINKS);

  return (
    <VariableHeightDrawer isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.closeWrapper}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <h2 className={classes.title}>
          {view === ShareCardView.volunteer
            ? "Share my campaign"
            : view === ShareCardView.orgShare
            ? "Share campaign"
            : `Invite ${volunteerLabelLC}`}
        </h2>

        {view === ShareCardView.orgShare && (
          <Fragment>
            <div className={classes.section}>
              <div className={classes.sectionHeader}>Share link</div>
              <div className={classes.socialIcons}>
                <Link
                  className={classes.socialIconWrapper}
                  href={facebookLink(link)}
                >
                  <FacebookBlueSmallSVG />
                </Link>
                <Link
                  className={classes.socialIconWrapper}
                  href={twitterLink(link, orgName, true)}
                >
                  <TwitterBlueSmallSVG />
                </Link>
                <Link
                  className={classes.socialIconWrapper}
                  href={linkedinLink(link)}
                >
                  <LinkedinBlueSmallSVG />
                </Link>
                <Link
                  className={classes.socialIconWrapper}
                  href={whatsappLink(link, orgName)}
                >
                  <WhatsappBlueSmallSVG />
                </Link>
                <Link
                  className={classes.socialIconWrapper}
                  href={textMessageLink(link, orgName)}
                >
                  <SmsOutlinedIcon fontSize="small" />
                </Link>
              </div>
            </div>
            <div className={classes.divider} />
          </Fragment>
        )}

        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            {view === ShareCardView.orgSignUp
              ? "Copy link to registration form"
              : "Copy link"}
          </div>
          <div className={classes.linkContainer}>
            <div>{link}</div>
            <CopyLink link={link}>
              <Button size="small" color="primary" className={classes.copy}>
                Copy
              </Button>
            </CopyLink>
          </div>
        </div>

        <div className={classes.divider} />

        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            {view === ShareCardView.volunteer
              ? "Scan for your personal fundraising page"
              : view === ShareCardView.orgShare
              ? "Share QR code for campaign"
              : "Share QR code for registration form"}
          </div>
          <div className={classes.scanContainer}>
            <QRCode
              id={`${view}-qr-drawer`}
              value={link}
              includeMargin={false}
              size={168}
            />
            <Button
              variant="text"
              color="primary"
              onClick={() => downloadQRCode(`${view}-qr-drawer`)}
              className={classes.button}
              startIcon={<GetAppOutlinedIcon />}
            >
              SAVE TO PHOTOS
            </Button>
          </div>
        </div>
      </div>
    </VariableHeightDrawer>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
  },
  closeWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 12,
    paddingRight: 12,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    marginBottom: 24,
    paddingLeft: 16,
  },
  section: {
    padding: "0 16px",
  },
  sectionHeader: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.11,
    lineHeight: "20px",
    marginBottom: 16,
  },
  socialIcons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: 280,
  },
  socialIconWrapper: {
    height: 40,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    border: `1px solid ${theme.palette.primary.main}`,
  },
  linkContainer: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#F5F5F5",
    padding: "18px 12px 18px 12px",
    color: theme.palette.text.secondary,
    fontSize: 14,
    letterSpacing: 0.11,
    lineHeight: "17px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflowWrap: "anywhere",
  },
  copy: {
    width: 66,
    minWidth: 66,
    marginLeft: 16,
    borderRadius: 15,
  },
  divider: {
    height: 24,
    borderBottom: "1px solid #DBDEEE",
    marginBottom: 24,
  },
  scanContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingBottom: 16,
  },
  button: {
    marginTop: 16,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.3,
  },
}));
