import { isProductCampaign, isRaffleCampaign, isVolunteer } from "../../../lib";
import { GenericObject } from "../../types";
import { RootState } from "../../types/state";
import { campaignTypes } from "./actions";

type CampaignState = {
  campaignAutocomplete: { id: number; campaign_id: number; name: string }[]; // `id` & `campaign_id` are the same and are used for different autocomplete's
  campaignId?: number;
  org_id?: number;
  campaign_type_id?: 1 | 2 | 3 | 4;
  orgName: string;
  webpageLink: string;
  show_volunteer_item_progress?: boolean | null;
  volunteerName: string;
  volunteerFirstName: string;
  contacts_confirmed?: boolean;
  campaignInfoFetched: boolean;
  no_initial_volunteer_setup?: boolean;
  setup_completed?: boolean;
  volunteerCampaignId?: number;
  text_drip_campaign?: boolean;
  agreed_to_receive_texts?: boolean;
  order_forms_to_org_due_date?: string | null;
  allow_associated_volunteer_accounts?: boolean;
  allow_family_accounts?: boolean;
  family_members?: null | GenericObject[];
  show_profit_based_progress?: boolean;
  start_date?: Date;
  end_date?: Date;
  orgSlug?: string;
  campaignSlug?: string;
  primary_color?: string;
  secondary_color?: string;
  volunteer_label?: string;
  no_student_leaderboard?: null | boolean;
  company_frontend_url?: string;
  orgEmail?: string;
  no_cash_donations?: boolean;
  ticketPackagesFetched: boolean;
  ticketPackages: { id: number; price: number; num_of_tickets: number }[];
  class_options?: null | string[];
  hasTeams?: boolean;
  student_count?: number | null;
  goal?: number;
  video_link?: string | null;
  picture?: string | null;
  company_name?: string;
  profit_paid_via_ach?: null | boolean;
  missingRequiredPrizeSelection?: boolean;
  allow_donations?: null | boolean;
  drip_email_subject?: null | string;
  hasMaterialBundle?: boolean;
  campaignGiveawayId?: null | number;
};

function initialState(noCampaignId?: boolean): CampaignState {
  const selectedCampaignId = localStorage.getItem("selectedCampaignId");
  return {
    campaignAutocomplete: [],
    campaignId: noCampaignId
      ? undefined
      : selectedCampaignId
      ? Number(selectedCampaignId)
      : undefined,
    orgName: "",
    webpageLink: "",
    volunteerName: "",
    volunteerFirstName: "",
    campaignInfoFetched: false,
    ticketPackagesFetched: false,
    ticketPackages: [],
  };
}

export const campaignReducers = (
  state = initialState(),
  action: any,
): CampaignState => {
  switch (action.type) {
    case campaignTypes.CAMPAIGN_AUTOCOMPLETE_SET:
      return { ...state, campaignAutocomplete: action.data };
    case campaignTypes.CAMPAIGN_INFO_SET:
      return { ...state, ...action.data, campaignInfoFetched: true };
    case campaignTypes.CAMPAIGN_INFO_UPDATE:
      return { ...state, ...action.data };
    case campaignTypes.CONTACTS_MARK_CONFIRMED:
      return { ...state, contacts_confirmed: true };
    case campaignTypes.CAMPAIGNS_SWITCH:
      return { ...initialState(true), campaignId: action.data };
    case campaignTypes.CAMPAIGN_CLEAR:
      return { ...initialState(true) };
    default:
      return state;
  }
};

export function getCampaignId(state: RootState) {
  return state.campaign.campaignId;
}

export function getVolunteerCampaignId(state: RootState) {
  return state.campaign.volunteerCampaignId;
}

export function getIsProductCampaign(state: RootState) {
  return isProductCampaign(state.campaign.campaign_type_id);
}

export function getIsRaffleCampaign(state: RootState) {
  return isRaffleCampaign(state.campaign.campaign_type_id);
}

export function getCampaignInfoFetched(state: RootState) {
  return state.campaign.campaignInfoFetched;
}

export function getHasTeams(state: RootState) {
  return Boolean(state.campaign.hasTeams);
}

export function getVolunteerLabel(state: RootState) {
  const _l = state.campaign.volunteer_label;
  const l = _l ? _l : "student";
  const label = l.replace(l[0], l[0].toUpperCase());
  return {
    volunteerLabel: `${label}s`,
    volunteerLabelLC: `${l}s`,
    volunteerLabelSing: label,
    volunteerLabelLCSingular: l,
  };
}

export function getShouldRedirectToVolunteerSetup(state: RootState) {
  const {
    user: { role },
    campaign: {
      no_initial_volunteer_setup,
      setup_completed,
      campaignId,
      missingRequiredPrizeSelection,
    },
  } = state;
  if (!isVolunteer(role)) return false;
  const impersonating = Boolean(
    localStorage.getItem("volunteerImpersonationToken"),
  );
  return Boolean(
    !impersonating &&
      campaignId &&
      (missingRequiredPrizeSelection ||
        (!no_initial_volunteer_setup && setup_completed === false)),
  );
}

export function getShowLeaderboard(state: RootState) {
  const {
    user: { role },
    campaign: { no_student_leaderboard },
  } = state;
  return !isVolunteer(role) || !no_student_leaderboard;
}

export function getTeamPageUrl(state: RootState, teamSlug: string) {
  const {
    campaign: { orgSlug, campaignSlug, company_frontend_url },
  } = state;
  return `${company_frontend_url}/${orgSlug}/${campaignSlug}/teams/${teamSlug}`;
}

export function getCampaignAutocomplete(state: RootState) {
  const ac = state.campaign.campaignAutocomplete;
  if (!Array.isArray(ac)) return [];
  return ac;
}
