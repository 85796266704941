import React, { useState, Fragment } from "react";
import {
  TextInput,
  List,
  Datagrid,
  TextField,
  Create,
  required,
  TopToolbar,
  NumberInput,
  SimpleForm,
  Filter,
  Notification,
  useNotify,
  useListContext,
  Edit,
  ArrayInput,
  SaveButton,
  Toolbar,
  DeleteWithConfirmButton,
  ArrayField,
  SingleFieldList,
  ChipField,
  SimpleFormIterator,
  AutocompleteInput,
  SelectInput,
} from "react-admin";
import { Button, Drawer, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

const requiredField = [required()];

const PrizeLevelFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Level Name" source="q" alwaysOn />
    </Filter>
  );
};

export function CompanyPrizeProgramTierPrizeLevels({
  company_prize_program_tier_id,
  basePath,
  prizeProducts,
  tierAutocomplete,
  allow_student_picks,
}) {
  const classes = styles();
  const [showCreate, setShowCreate] = useState(false);

  const Actions = () => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setShowCreate(true)}
      >
        add tier prize level
      </Button>
    </TopToolbar>
  );

  return (
    <div className={classes.container}>
      <List
        resource="company_prize_program_tier_prize_levels"
        basePath={basePath}
        perPage={25}
        title=" "
        bulkActionButtons={false}
        exporter={false}
        empty={false}
        filter={{ company_prize_program_tier_id }}
        filters={<PrizeLevelFilter />}
        actions={<Actions />}
        component="div"
      >
        <GridWrapper
          company_prize_program_tier_id={company_prize_program_tier_id}
          basePath={basePath}
          showCreate={showCreate}
          setShowCreate={setShowCreate}
          prizeProducts={prizeProducts}
          allow_student_picks={allow_student_picks}
          tierAutocomplete={tierAutocomplete}
        />
      </List>
    </div>
  );
}

const GridWrapper = ({
  company_prize_program_tier_id,
  basePath,
  showCreate,
  setShowCreate,
  prizeProducts,
  allow_student_picks,
  tierAutocomplete,
  ...props
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  return (
    <div>
      <Datagrid
        {...props}
        rowClick={(id, basePath, record) => {
          setSelectedRow({ id, basePath, record });
          setShowEdit(true);
        }}
      >
        <TextField source="id" />
        <TextField source="level_name" label="Level Name" />
        <TextField
          source="number_of_prizes_to_assign"
          label="Number Of Prizes To Assign"
        />
        <ArrayField
          source="company_prize_program_tier_prize_level_products"
          label="Prize Products"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="prizeProduct" />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
      <AddTierPrizeLevel
        basePath={basePath}
        showCreate={showCreate}
        setShowCreate={setShowCreate}
        company_prize_program_tier_id={company_prize_program_tier_id}
        allow_student_picks={allow_student_picks}
        tierAutocomplete={tierAutocomplete}
      />
      <EditTierPrizeLevel
        selectedRow={selectedRow}
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        prizeProducts={prizeProducts}
        allow_student_picks={allow_student_picks}
        tierAutocomplete={tierAutocomplete}
      />
    </div>
  );
};

function AddTierPrizeLevel({
  showCreate,
  setShowCreate,
  basePath,
  company_prize_program_tier_id,
  allow_student_picks,
  tierAutocomplete,
}) {
  const classes = styles();
  const notify = useNotify();
  const { refetch } = useListContext();

  const transformDataForSubmit = values => {
    return { company_prize_program_tier_id, ...values };
  };

  return (
    <Drawer open={showCreate} anchor="right">
      <div className={classes.drawer}>
        <div className={classes.drawerHeader}>
          <div>Add a Tier Prize Level</div>
          <IconButton onClick={() => setShowCreate(false)} size="small">
            <CloseIcon />
          </IconButton>
        </div>
        <Create
          resource="company_prize_program_tier_prize_levels"
          basePath={basePath}
          onSuccess={() => {
            refetch();
            notify("Tier Prize Level added successfully");
            setShowCreate(false);
          }}
          transform={transformDataForSubmit}
        >
          <SimpleForm>
            <TextInput
              source="level_name"
              label="Level Name"
              validate={requiredField}
            />
            <NumberInput
              source="number_of_prizes_to_assign"
              validate={requiredField}
              label="Number Of Prizes To Assign"
              defaultValue={1}
            />
            {allow_student_picks && (
              <SelectInput
                fullWidth
                source="copy_student_picks_from_tier_id"
                label="Copy Student Prize Selections From This Tier (for cumulative prize programs)"
                choices={tierAutocomplete}
                resettable
                translateChoice={false}
              />
            )}
            <Fragment>
              <div className={classes.msg}>
                After you SAVE you can add Prizes
              </div>
            </Fragment>
          </SimpleForm>
        </Create>
      </div>
      <Notification />
    </Drawer>
  );
}

function EditTierPrizeLevel({
  showEdit,
  setShowEdit,
  selectedRow,
  prizeProducts,
  allow_student_picks,
  tierAutocomplete,
}) {
  const { id = "", basePath = "" } = selectedRow;
  const classes = styles();
  const notify = useNotify();
  const { refetch } = useListContext();

  const transformDataForSubmit = values => {
    const {
      level_name,
      company_prize_program_tier_id,
      number_of_prizes_to_assign,
      company_prize_program_tier_prize_level_products,
      copy_student_picks_from_tier_id,
    } = values;
    return {
      level_name,
      company_prize_program_tier_id,
      number_of_prizes_to_assign,
      company_prize_program_tier_prize_level_products,
      copy_student_picks_from_tier_id,
    };
  };

  const closeAndRefetch = () => {
    refetch();
    setShowEdit(false);
  };

  if (!id) return <></>;
  return (
    <Drawer open={showEdit} anchor="right">
      <div className={classes.drawer}>
        <div className={classes.drawerHeader}>
          <div>Edit Tier Prize Level</div>
          <IconButton onClick={closeAndRefetch} size="small">
            <CloseIcon />
          </IconButton>
        </div>
        <Edit
          resource="company_prize_program_tier_prize_levels"
          basePath={basePath}
          id={id}
          undoable={false}
          onSuccess={() => {
            closeAndRefetch();
            notify("Tier Prize Level updated successfully");
          }}
          transform={transformDataForSubmit}
        >
          <SimpleForm
            toolbar={
              <Toolbar>
                <SaveButton />
                <DeleteWithConfirmButton
                  basePath={basePath}
                  onSuccess={() => {
                    closeAndRefetch();
                    notify("Tier Prize Level removed successfully");
                  }}
                  undoable={false}
                  className={classes.delete}
                />
              </Toolbar>
            }
          >
            <TextInput
              source="level_name"
              label="Level Name"
              validate={requiredField}
            />
            <NumberInput
              source="number_of_prizes_to_assign"
              validate={requiredField}
              label="Number Of Prizes To Assign"
              defaultValue={1}
            />
            {allow_student_picks && (
              <SelectInput
                fullWidth
                source="copy_student_picks_from_tier_id"
                label="Copy Student Prize Selections From This Tier (for cumulative prize programs)"
                choices={tierAutocomplete}
                resettable
                translateChoice={false}
              />
            )}
            <Fragment>
              <ul>
                <li>
                  If you add a Configurable product we will add all its Variants
                  for you.
                </li>
                <li>
                  If you add Variants and not their Parent/Configurable product
                  they will nor be available for prizing.
                </li>
                <li>
                  We can not auto assign configurable products for obvious
                  reasons (we don't know which color/size etc. to pick).
                </li>
              </ul>
            </Fragment>
            <ArrayInput
              source="company_prize_program_tier_prize_level_products"
              label="Prize Level Products"
            >
              <SimpleFormIterator disableReordering>
                <AutocompleteInput
                  source="product_id"
                  label="Select Product"
                  choices={prizeProducts}
                  translateChoice={false}
                  validate={requiredField}
                  fullWidth
                />
                <NumberInput
                  source="precedence_order_number_for_auto_assigning"
                  label="Precedence Order Number For Auto Assigning"
                  fullWidth
                />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleForm>
        </Edit>
      </div>
      <Notification />
    </Drawer>
  );
}

const styles = makeStyles(theme => ({
  container: {
    margin: "12px 16px",
  },
  drawer: {
    minWidth: 650,
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100vw",
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px 0 16px",
    fontSize: 18,
  },
  delete: {
    position: "absolute",
    right: 16,
  },
  msg: { fontSize: 16, color: "red" },
}));
