import React, { useState, useEffect } from "react";
import {
  TextInput,
  required,
  useNotify,
  NumberInput,
  SimpleForm,
  Notification,
  Edit,
  useDataProvider,
  useListContext,
} from "react-admin";
import {
  Paper,
  Drawer,
  IconButton,
  Tabs,
  Tab,
  Divider,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CompanyPrizeProgramTierPrizeLevels } from "./CompanyPrizeProgramTierPrizeLevels";
import { CompanyPrizeProgramTierRules } from "./CompanyPrizeProgramTierRules";
import { APIClient, generateAuthHeader } from "../../lib";

const requiredField = [required()];

export function CompanyPrizeProgramTierDetails({
  selectedRow,
  showTierDetails,
  setShowTierDetails,
  prizeProducts,
}) {
  const {
    id: company_prize_program_tier_id = "",
    basePath = "",
    record: { tier_name = "", company_prize_program_id } = {},
    prizeProgramRecord: {
      allow_student_picks,
      send_approaching_tier_notifications: hasTierNotifications,
    } = {},
  } = selectedRow;

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [tabIndex, setTabIndex] = useState(0);
  const [tierAutocomplete, setTierAutocomplete] = useState([]);
  const classes = styles();
  const { refetch } = useListContext();

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        `/company_prize_program_tiers/autocomplete?company_prize_program_id=${company_prize_program_id}`,
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setTierAutocomplete(data);
    };
    if (company_prize_program_id && allow_student_picks) fetch();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_prize_program_id, allow_student_picks]);

  const transformDataForSubmit = values => {
    const { id: _rm, ...rest } = values;
    return rest;
  };

  const onClose = () => {
    refetch();
    setShowTierDetails(false);
  };

  return (
    <Drawer
      open={showTierDetails}
      anchor="right"
      PaperProps={{ classes: { root: classes.drawerPaper } }}
    >
      <div className={classes.drawer}>
        <div className={classes.header}>
          <div className={classes.tier}>{`TIER: ${tier_name} `}</div>
          <div>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <Paper className={classes.container}>
          <Tabs
            value={tabIndex}
            onChange={(_, index) => setTabIndex(index)}
            indicatorColor="primary"
          >
            <Tab label="Tier Config" />
            <Tab label="Tier Prize Levels" />
            <Tab label="Tier Rules" />
          </Tabs>
          <Divider />
          {tabIndex === 0 && (
            <Edit
              resource="company_prize_program_tiers"
              id={company_prize_program_tier_id}
              basePath={basePath}
              onSuccess={() => {
                dataProvider.getOne("company_prize_program_tiers", {
                  id: company_prize_program_tier_id,
                });
                notify("Config updated successfully");
              }}
              title=" "
              undoable={false}
              component="div"
              transform={transformDataForSubmit}
            >
              <SimpleForm>
                <TextInput
                  source="tier_name"
                  label="Tier Name"
                  validate={requiredField}
                />
                <NumberInput
                  source="tier_order_number"
                  validate={requiredField}
                  label="Tier Order Number"
                />
                {allow_student_picks && (
                  <TextInput
                    fullWidth
                    source="prize_pick_message"
                    label="Tier Message For Student Prize Picking"
                  />
                )}
              </SimpleForm>
            </Edit>
          )}
          {tabIndex === 1 && (
            <CompanyPrizeProgramTierPrizeLevels
              company_prize_program_tier_id={company_prize_program_tier_id}
              basePath={basePath}
              prizeProducts={prizeProducts}
              tierAutocomplete={tierAutocomplete}
              allow_student_picks={allow_student_picks}
            />
          )}
          {tabIndex === 2 && (
            <CompanyPrizeProgramTierRules
              company_prize_program_tier_id={company_prize_program_tier_id}
              basePath={basePath}
              hasTierNotifications={hasTierNotifications}
            />
          )}
        </Paper>
      </div>
      <Notification />
    </Drawer>
  );
}

const styles = makeStyles(_theme => ({
  drawerPaper: {
    minWidth: "90vw",
  },
  drawer: {
    minWidth: "100%",
    position: "relative",
  },
  container: {
    margin: 24,
    marginTop: 0,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    margin: 24,
  },
  tier: {
    fontSize: 22,
  },
}));
