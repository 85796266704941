import { useState } from "react";
import { IconButton, Button, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { APIAuthClient } from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { useDispatch } from "react-redux";
import { setToast } from "../../../state";
import { ButtonSpinner, ResponsiveModal } from "../../../components";
import { useIsMobile, useDrawerTransition } from "../../../hooks/ui";
import { hexToRGBA } from "../../../../lib";

type Props = {
  teamData: GenericObject;
  onClose: () => void;
  refreshList: () => void;
};
export function RemoveTeamFromCampaign({
  teamData,
  onClose: _onClose,
  refreshList,
}: Props) {
  const { id, teamCampaignId, team_name } = teamData;
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async () => {
    setSubmitting(true);
    const url = `/team_campaigns/${teamCampaignId}`;
    const res = await APIAuthClient.delete<any, APIRes>(url);
    const { error, errorMessage } = res;
    if (error) {
      setSubmitting(false);
      return dispatch(setToast(errorMessage));
    }
    dispatch(setToast("Team removed from campaign", ToastTypes.success));
    onClose();
    refreshList();
  };

  if (!id || !teamCampaignId) return <></>;
  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} dynamicHeight>
      <div className={classes.container}>
        {isMobile && (
          <div className={classes.closeWrapper}>
            <IconButton className={classes.close} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <div className={classes.content}>
          <h1 className={classes.title}>
            Are you sure you want to remove Team {team_name} from this campaign?
          </h1>
          <p className={classes.subTitle}>
            All team members and sales will be removed from the team.
          </p>
        </div>

        <div className={classes.actions}>
          <Button
            variant={isMobile ? "contained" : "text"}
            className={classes.cancel}
            onClick={onClose}
            size={isMobile ? "medium" : "small"}
            disabled={submitting}
            color={isMobile ? "secondary" : "default"}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            size={isMobile ? "medium" : "small"}
            variant={isMobile ? "contained" : "text"}
            className={classes.delete}
            disabled={submitting}
            onClick={onSubmit}
          >
            Delete
            <ButtonSpinner show={submitting} />
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  closeWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  close: {
    color: "rgba(0, 0, 0, 0.33)",
    padding: 12,
  },
  content: {
    padding: "16px 24px 50px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px 48px 16px",
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    paddingBottom: 8,
  },
  subTitle: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "24px",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 16,
    padding: "0 8px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "0 16px",
    },
  },
  delete: {
    width: 67,
    borderRadius: 4,
    color: theme.palette.error.main,
    textTransform: "uppercase",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: hexToRGBA(theme.palette.error.main, 0.1),
    },
    [theme.breakpoints.down("sm")]: {
      width: 160,
      borderRadius: 18,
      textTransform: "none",
      color: "#FFFFFF",
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  cancel: {
    width: 73,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    marginRight: 6,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: 160,
      borderRadius: 18,
      marginRight: 16,
      color: theme.palette.secondary.contrastText,
      textTransform: "none",
    },
  },
}));
