import React, { useState, Fragment } from "react";
import {
  Toolbar,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import UploadIcon from "@material-ui/icons/Publish";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SchoolIcon from "@material-ui/icons/School";
import FaceIcon from "@material-ui/icons/Face";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import EventIcon from "@material-ui/icons/Event";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import {
  shipstationShipToSchool,
  neighborsReport,
  shipToSchoolStudentProductReport,
  dinnerReport,
  dedicationsExport,
  personalizationReport,
  pullSheet,
  studentLabels,
  mrsFieldsOrderAggregatesXml,
  bigKahunaTopCampaignProducts,
} from "./donationExports";
import {
  isGivvrCompany,
  isMrsFieldsCompany,
  isSimplyGoodnessCompany,
  isOrgOrOrgEmployee,
  history,
  isVolunteer,
  isGreatWesternSupplier,
  isNeighborsSupplier,
  isKidsAreFirstCompany,
  isBigKahunaCompany,
  isCompanyDataEntry,
  isCompanyAdminOrDataEntry,
  isProductCampaign,
  isRaffleCampaign,
  isCompanyOrCompanyAdmin,
} from "../../lib";
import { Uploader } from "./Uploader";
import { BrochureUploadModal } from "./BrochureUploadModal";
import { StudentPickSlipsDownload } from "./components/StudentPickSlipsDownload";
import { STHShipstationDownload } from "./components/STHShipstationDownload";
import { CPTBrochureUpload } from "./CPTBrochureUpload";
import { CampaignDonationDownloads } from "./components/CampaignDonationDownloads";
import { SendSTHOrdersToShipstation } from "./components/SendSTHOrdersToShipstation";
import { styles } from "./Donation.styles";

export function CampaignDonationListActions(props) {
  const classes = styles();
  const {
    forCampaignShow,
    role,
    companyId,
    org_id,
    campaign_id,
    productSupplierId,
    campaign_type_id,
    allowFamilyAccounts,
    isRepOrHigherUser,
    canManage,
    no_cash_donations,
    allow_donations,
    // action props
    filterValues,
  } = props;
  const isProduct = isProductCampaign(campaign_type_id);
  const isRaffle = isRaffleCampaign(campaign_type_id);
  const isGivvr = isGivvrCompany(companyId);
  const isMrsFields = isMrsFieldsCompany(companyId);
  const isSimplyGoodness = isSimplyGoodnessCompany(companyId);
  const isKidsAreFirst = isKidsAreFirstCompany(companyId);
  const isBigKahuna = isBigKahunaCompany(companyId);
  const isGreatWestern = isGreatWesternSupplier(productSupplierId);
  const isNeighbors = isNeighborsSupplier(productSupplierId);
  const isDataEntry = isCompanyDataEntry(role);
  const [uploadModal, setUploadModal] = useState(false);
  const [brochureModal, setBrochureModal] = useState(false);
  const [CPTBrochureModal, setCPTBrochureModal] = useState(false);
  const [studentPickSlipsModal, setStudentPickSlipsModal] = useState(false);
  const createLabelTxt = isProduct ? "Add a Sale" : "add donation";
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = e => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Fragment>
      <Toolbar>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            endIcon={<ExpandMoreIcon />}
          >
            Actions
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.menu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {!forCampaignShow && (
              <MenuItem onClick={() => history.push(`donations/create`)}>
                <ListItemIcon>
                  <AddIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={createLabelTxt} />
              </MenuItem>
            )}
            {!no_cash_donations && isOrgOrOrgEmployee(role) && (
              <MenuItem
                onClick={() => {
                  setUploadModal(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <UploadIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={`Upload ${isProduct ? "Orders" : "Donations"}`}
                />
              </MenuItem>
            )}

            {!isProduct && (
              <CampaignDonationDownloads
                {...props}
                handleClose={handleClose}
                reportType="donation"
                label="Donation Export"
              />
            )}

            {isRaffle && !isVolunteer(role) && (
              <CampaignDonationDownloads
                {...props}
                handleClose={handleClose}
                reportType="raffle"
                label="Ticket Export"
              />
            )}

            {isProduct && (
              <div>
                {(canManage || isMrsFields) && (
                  <MenuItem
                    onClick={() => {
                      setBrochureModal(true);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <AddIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Add Order Form" />
                  </MenuItem>
                )}
                <CampaignDonationDownloads
                  {...props}
                  handleClose={handleClose}
                  reportType="order"
                  label="Order Export"
                />
                <CampaignDonationDownloads
                  {...props}
                  handleClose={handleClose}
                  reportType="byProduct"
                  label="By Product Export"
                />
              </div>
            )}
            {isProduct && !isDataEntry && (
              <div>
                <STHShipstationDownload
                  {...filterValues}
                  campaign_id={campaign_id}
                  asMenuItem
                  callback={handleClose}
                />

                {isCompanyOrCompanyAdmin(role) && (
                  <SendSTHOrdersToShipstation
                    campaign_id={campaign_id}
                    callback={handleClose}
                  />
                )}

                <MenuItem
                  onClick={() => {
                    shipstationShipToSchool(campaign_id);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <SchoolIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="STS ShipStation" />
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    pullSheet(campaign_id);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <SchoolIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="STS Pull Sheet" />
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setStudentPickSlipsModal(true);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <SchoolIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="STS Pick Slips" />
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    studentLabels(campaign_id);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <SchoolIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="STS Student Labels" />
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    shipToSchoolStudentProductReport(campaign_id);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <SchoolIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="STS Student Products" />
                </MenuItem>

                {(isMrsFields || isSimplyGoodness || isKidsAreFirst) && (
                  <MenuItem
                    onClick={() => {
                      mrsFieldsOrderAggregatesXml(campaign_id);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <FolderSpecialIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={isKidsAreFirst ? "KAF-FM" : "Order XML"}
                    />
                  </MenuItem>
                )}

                {isRepOrHigherUser && isBigKahuna && (
                  <MenuItem
                    onClick={() => {
                      personalizationReport(campaign_id);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <FaceIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Personalization Report" />
                  </MenuItem>
                )}

                {isRepOrHigherUser && isBigKahuna && isProduct && (
                  <MenuItem
                    onClick={() => {
                      bigKahunaTopCampaignProducts(campaign_id);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <EmojiEventsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Top Campaign Products" />
                  </MenuItem>
                )}
              </div>
            )}
            {isBigKahuna && isProduct && isCompanyAdminOrDataEntry(role) && (
              <MenuItem
                onClick={() => {
                  setCPTBrochureModal(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <UploadIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="CPT Order Form Upload" />
              </MenuItem>
            )}

            {isRepOrHigherUser && (isNeighbors || isGreatWestern) && (
              <MenuItem
                onClick={() => {
                  neighborsReport(campaign_id);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <FolderSpecialIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Neighbors Report" />
              </MenuItem>
            )}

            {isGivvr && !isDataEntry && (
              <MenuItem
                onClick={() => {
                  dinnerReport(campaign_id);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <RestaurantIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Dinner Export" />
              </MenuItem>
            )}

            {!isProduct && !isDataEntry && (
              <MenuItem
                onClick={() => {
                  dedicationsExport(campaign_id);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <EventIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Dedications Export" />
              </MenuItem>
            )}
          </Menu>
        </div>
      </Toolbar>

      {uploadModal && (
        <Uploader
          setUploadModal={setUploadModal}
          campaignId={campaign_id}
          forProduct={isProduct}
        />
      )}

      {brochureModal && (
        <BrochureUploadModal
          setShowModal={setBrochureModal}
          campaignId={campaign_id}
          orgId={org_id}
          allowFamilyAccounts={allowFamilyAccounts}
          allowDonations={allow_donations}
        />
      )}

      {CPTBrochureModal && (
        <CPTBrochureUpload
          setShowModal={setCPTBrochureModal}
          campaign_id={campaign_id}
        />
      )}

      {studentPickSlipsModal && (
        <StudentPickSlipsDownload
          setShowModal={setStudentPickSlipsModal}
          campaign_id={campaign_id}
        />
      )}
    </Fragment>
  );
}
