import React from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  makeStyles,
} from "@material-ui/core";
import { formatMoney } from "../../../lib";

export function ProfitAndCommissionAdditionalInfo({ data }) {
  const {
    tipTotal,
    processingFeeTotal,
    use_tips,
    tip_title,
    use_processing_fee,
    processing_fee_title,
    prizeProgram,
    repNotes,
  } = data;
  const classes = styles();

  if (!use_tips && !use_processing_fee && !prizeProgram && !repNotes) {
    return <></>;
  }
  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              ADDITIONAL INFO
              <div className={classes.additionalInfoWarning}>
                This Info is NOT automatically added to the statement
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {use_tips && (
            <TableRow>
              <TableCell>
                Tips:
                {tip_title && (
                  <div className={classes.additionalInfoLabel}>
                    ({tip_title})
                  </div>
                )}
              </TableCell>
              <TableCell>{formatMoney(tipTotal)}</TableCell>
            </TableRow>
          )}
          {use_processing_fee && (
            <TableRow>
              <TableCell>
                Processing Fee:
                {processing_fee_title && (
                  <div className={classes.additionalInfoLabel}>
                    ({processing_fee_title})
                  </div>
                )}
              </TableCell>
              <TableCell>{formatMoney(processingFeeTotal)}</TableCell>
            </TableRow>
          )}
          {prizeProgram && (
            <TableRow>
              <TableCell>Prize Program:</TableCell>
              <TableCell>{prizeProgram}</TableCell>
            </TableRow>
          )}
          {repNotes && (
            <TableRow>
              <TableCell>Rep Notes:</TableCell>
              <TableCell className={classes.repNotes}>{repNotes}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const styles = makeStyles(_theme => ({
  table: {
    margin: "24px 0",
    width: "fit-content",
    height: "fit-content",
    minWidth: 300,
    marginLeft: 42,
  },
  additionalInfoWarning: {
    fontStyle: "italic",
    fontSize: 12,
    color: "#FF0000",
  },
  additionalInfoLabel: {
    fontStyle: "italic",
    fontSize: 12,
  },
  repNotes: {
    whiteSpace: "pre-wrap",
  },
}));
