import { useState, Fragment } from "react";
import {
  IconButton,
  Theme,
  makeStyles,
  Button,
  TextField as MUITextField,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import ChevronLeft from "@material-ui/icons/ChevronLeftOutlined";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Field, Form } from "react-final-form";
import { FormApi } from "final-form";
import { TextField } from "final-form-material-ui";
import { ButtonSpinner, ResponsiveModal } from "..";
import classNames from "classnames";
import {
  composeValidators,
  contactEmailOrPhoneValidation,
  emailValidation,
  requiredField,
} from "../../lib";
import { GenericObject } from "../../types";
import { contactActions, getCampaignId } from "../../state";
import { useAppDispatch } from "../../types/state";
import { useIsDesktop, useIsMobile } from "../../hooks/ui";
import { IS_RN_WEBVIEW } from "../../../lib";
const EMPTY_CONTACT = { name: "", phone: "", email: "" };

enum Modes {
  oneByOne = "oneByOne",
  bulk = "bulk",
  bulkReview = "bulkReview",
}
type Contact = { name: string; email: string; phone: string };
type Props = {
  isOpen: boolean;
  onClose: () => void;
  openImportModal: () => void;
};

export function AddContacts({
  isOpen,
  onClose: _onClose,
  openImportModal,
}: Props) {
  const dispatch = useAppDispatch();
  const [mode, setMode] = useState<Modes>(Modes.oneByOne);
  const classes = styles({ mode });
  const [bulkValue, setBulkValue] = useState("");
  const [initialValues, setInitialValues] = useState({
    contacts: [EMPTY_CONTACT],
  });
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const campaign_id = useSelector(getCampaignId);

  const changeMode = (newMode: Modes, form: FormApi) => {
    setInitialValues({ contacts: [EMPTY_CONTACT] });
    setBulkValue("");
    form.reset();
    setMode(newMode);
  };

  const onClose = () => {
    _onClose();
    setTimeout(() => setInitialValues({ contacts: [EMPTY_CONTACT] }), 1000);
    setBulkValue("");
    setMode(Modes.oneByOne);
  };

  const convertBulkInputToContacts = () => {
    const EMAIL_TEST = /@/;
    const contacts: Contact[] = [];
    const rows = bulkValue.split("\n");
    rows.forEach(row => {
      if (!row) return;
      const [v1, v2] = row.split(",");
      let email = "";
      let phone = "";
      if (EMAIL_TEST.test(v1 || "")) {
        email = v1.trim();
        if (v2) phone = v2.trim();
      } else {
        if (v1) phone = v1.trim();
        if (EMAIL_TEST.test(v2 || "")) email = v2.trim();
      }

      if (email || phone) {
        contacts.push({ name: "Supporter", email, phone });
      }
    });
    if (!contacts.length) contacts.push(EMPTY_CONTACT);
    setInitialValues({ contacts });
    setMode(Modes.bulkReview);
  };

  const onSubmit = async (values: any) => {
    const contacts = values.contacts.map((c: GenericObject) => {
      const { name, email, phone } = c;
      return {
        email: email ? email : null,
        phone: phone ? phone : null,
        name,
      };
    });
    const contactCount = contacts.length;
    const success = await dispatch(
      contactActions.addContacts({ contacts, campaign_id }, contactCount),
    );

    if (success) onClose();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <Form
          mutators={{ ...arrayMutators }}
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, form }) => {
            return (
              <Fragment>
                <div className={classes.topContent}>
                  <div className={classes.titleAndBack}>
                    {isDesktop && mode === Modes.bulkReview && (
                      <IconButton
                        size="small"
                        onClick={() => setMode(Modes.bulk)}
                        edge="start"
                      >
                        <ChevronLeft fontSize="large" />
                      </IconButton>
                    )}
                    <h1 className={classes.title}>Add contacts</h1>
                  </div>

                  {isMobile && (
                    <IconButton
                      size="small"
                      className={classes.close}
                      onClick={onClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}

                  {isMobile && IS_RN_WEBVIEW && (
                    <div className={classes.import}>
                      Want to bring in your contacts directly from your phone?{" "}
                      <span
                        className={classes.importLink}
                        onClick={() => {
                          onClose();
                          openImportModal();
                        }}
                      >
                        Import phone contacts
                      </span>
                    </div>
                  )}

                  <div className={classes.toggleWrapper}>
                    <div className={classes.toggle}>
                      <div
                        className={classNames(classes.toggleOption, {
                          [classes.activeOption]: mode === Modes.oneByOne,
                        })}
                        onClick={() =>
                          mode !== Modes.oneByOne
                            ? changeMode(Modes.oneByOne, form)
                            : null
                        }
                      >
                        One-by-one
                      </div>
                      <div
                        className={classNames(classes.toggleOption, {
                          [classes.activeOption]:
                            mode === Modes.bulk || mode === Modes.bulkReview,
                        })}
                        onClick={() =>
                          mode === Modes.oneByOne
                            ? changeMode(Modes.bulk, form)
                            : null
                        }
                      >
                        Bulk entry
                      </div>
                    </div>
                  </div>
                </div>

                <form onSubmit={handleSubmit} className={classes.form}>
                  {mode === Modes.bulk && (
                    <div className={classes.bulkContainer}>
                      <p className={classes.bulkP}>
                        Enter each contact's phone and/or email info on a new
                        line.
                      </p>
                      <p className={classes.bulkP}>
                        Separate phone number and email address with a comma.
                      </p>
                      <MUITextField
                        multiline
                        minRows={6}
                        type="textarea"
                        fullWidth
                        value={bulkValue}
                        className={classes.bulkInput}
                        placeholder={`7701234567, auntie1@example.com\nbarbra123@example.com, 5161234567\n9171234567\nphilbrown@example.com`}
                        onChange={({ target }) => setBulkValue(target.value)}
                      />
                    </div>
                  )}

                  {mode !== Modes.bulk && (
                    <div>
                      {mode === Modes.bulkReview && (
                        <div className={classes.addDetailsWrapper}>
                          {isMobile && (
                            <IconButton
                              size="small"
                              onClick={() => setMode(Modes.bulk)}
                              edge="start"
                            >
                              <ChevronLeft fontSize="small" />
                            </IconButton>
                          )}
                          <div className={classes.addDetails}>
                            Add contacts details (optional)
                          </div>
                        </div>
                      )}
                      <FieldArray name="contacts">
                        {({ fields }) => (
                          <Fragment>
                            {fields.map((fieldName, index) => {
                              const length = fields.length ? fields.length : 0;
                              const notLast = index < length - 1;
                              return (
                                <div
                                  className={classNames(classes.contactGroup, {
                                    [classes.cgBorder]: notLast,
                                  })}
                                  key={index}
                                >
                                  <div className={classes.inputGroup}>
                                    <Field
                                      name={`${fieldName}.name`}
                                      variant="outlined"
                                      component={TextField}
                                      size="small"
                                      validate={requiredField}
                                      placeholder="Name"
                                      className={classes.input}
                                    />
                                    <Field
                                      name={`${fieldName}.phone`}
                                      variant="outlined"
                                      component={TextField}
                                      size="small"
                                      validate={contactEmailOrPhoneValidation}
                                      placeholder="Phone"
                                      className={classes.input}
                                    />
                                    <Field
                                      name={`${fieldName}.email`}
                                      variant="outlined"
                                      component={TextField}
                                      size="small"
                                      validate={composeValidators(
                                        emailValidation,
                                        contactEmailOrPhoneValidation,
                                      )}
                                      placeholder="Email"
                                      className={classNames(
                                        classes.input,
                                        classes.emailInput,
                                      )}
                                    />
                                  </div>
                                  <IconButton
                                    size="small"
                                    className={classes.clear}
                                    onClick={() => {
                                      length < 2
                                        ? form.reset()
                                        : fields.remove(index);
                                    }}
                                  >
                                    <CloseIcon color="primary" />
                                  </IconButton>
                                </div>
                              );
                            })}
                            {mode === Modes.oneByOne && (
                              <Button
                                color="primary"
                                variant="text"
                                className={classes.addAnother}
                                startIcon={<AddIcon />}
                                onClick={() =>
                                  fields.push({
                                    name: "",
                                    phone: "",
                                    email: "",
                                  })
                                }
                              >
                                Add another contact
                              </Button>
                            )}
                          </Fragment>
                        )}
                      </FieldArray>
                    </div>
                  )}

                  <div className={classes.actions}>
                    {!isMobile && (
                      <Button
                        variant="text"
                        className={classes.cancel}
                        onClick={onClose}
                      >
                        CANCEL
                      </Button>
                    )}
                    {mode !== Modes.bulk && (
                      <Button
                        color="primary"
                        type="submit"
                        variant={isMobile ? "contained" : "text"}
                        className={classes.save}
                        disabled={submitting}
                      >
                        Save contacts
                        <ButtonSpinner show={submitting} />
                      </Button>
                    )}
                    {mode === Modes.bulk && (
                      <Button
                        color="primary"
                        variant={isMobile ? "contained" : "text"}
                        className={classes.continue}
                        onClick={convertBulkInputToContacts}
                      >
                        Continue to contact details
                      </Button>
                    )}
                  </div>
                </form>
              </Fragment>
            );
          }}
        />
      </div>
    </ResponsiveModal>
  );
}

type StyleProps = {
  mode: Modes;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    width: 700,
    minHeight: 300,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "100%",
    },
  },
  topContent: {
    padding: "16px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 16,
    marginBottom: ({ mode }) => (mode === Modes.oneByOne ? 22 : 12),
    borderBottom: "1px solid #DBDEEE",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "unset",
      marginBottom: 0,
      padding: "16px 16px 24px 16px",
      position: "relative",
      borderBottom: "none",
    },
  },
  close: {
    position: "absolute",
    top: 10,
    right: 10,
    color: "rgba(0, 0, 0, 0.33)",
  },
  titleAndBack: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
  },
  import: {
    marginTop: 4,
    fontSize: 14,
    letterSpacing: 0.13,
    lineHeight: "20px",
  },

  importLink: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  toggleWrapper: {
    marginRight: 202,
    [theme.breakpoints.down("sm")]: {
      marginTop: 24,
      marginRight: 0,
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  toggle: {
    width: 248,
    height: 32,
    borderRadius: 18,
    backgroundColor: "rgba(5,21,81,0.08)",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: 300,
      height: 36,
    },
  },
  toggleOption: {
    width: 124,
    height: 32,
    borderRadius: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(0,0,0,0.33)",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.3,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: 150,
      height: 36,
    },
  },
  activeOption: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: "unset",
  },
  form: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  contactGroup: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  cgBorder: {
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid #EAEBF3",
    },
  },
  inputGroup: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    marginLeft: 24,
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginLeft: 0,
      marginRight: 0,
    },
  },
  input: {
    width: 160,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 15,
      padding: "0 8px 0 16px",
      width: "100%",
    },
  },
  emailInput: {
    width: 240,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  clear: {
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      marginRight: 16,
    },
  },
  addAnother: {
    marginLeft: 24,
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
    textTransform: "uppercase",
    borderRadius: 4,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 16,
    },
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 16,
    marginTop: 40,
    padding: "0 12px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  save: {
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      textTransform: "none",
      width: 300,
      borderRadius: 18,
    },
  },
  cancel: {
    width: 71,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    marginRight: 16,
  },
  continue: {
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      textTransform: "none",
      width: 300,
      borderRadius: 18,
    },
  },
  bulkContainer: {
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  bulkP: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      letterSpacing: 0.15,
      lineHeight: "20px",
      color: theme.palette.text.secondary,
    },
  },
  bulkInput: {
    marginTop: 16,
  },
  addDetailsWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    marginLeft: 24,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 16,
      color: theme.palette.text.secondary,
    },
  },
  addDetails: {
    fontSize: 16,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
}));
