import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  Button,
  IconButton,
  makeStyles,
  Checkbox,
  TextField as MuiTextField,
} from "@material-ui/core";
import { FormApi } from "final-form";
import { OnChange } from "react-final-form-listeners";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { TextField } from "final-form-material-ui";
import {
  APIAuthClient,
  requiredField,
  requiredPositiveInteger,
} from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { getCampaignId, getVolunteerLabel, setToast } from "../../../state";
import { ButtonSpinner, ResponsiveModal } from "../../../components";
import { CircularLoader } from "../../../components/ui/CircularLoader";
import { useIsMobile } from "../../../hooks/ui";
import classNames from "classnames";
import { RemoveTeamFromCampaign } from "./RemoveTeamFromCampaign";

type VolunteerAC = {
  id: number;
  name: string;
  nameWithId: string;
  onTeam: boolean;
  onAnotherTeam: boolean;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  refreshList: () => void;
  id: number;
  teamPage: string;
};

export function ManageTeam({
  id,
  isOpen,
  onClose: _onClose,
  refreshList,
  teamPage,
}: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const campaignId = useSelector(getCampaignId);
  const { volunteerLabel } = useSelector(getVolunteerLabel);
  const [loaded, setLoaded] = useState(false);
  const [tempMembers, setTempMembers] = useState<VolunteerAC[]>([]);
  const [addedMembers, setAddedMembers] = useState<GenericObject>({});
  const [removedMembers, setRemovedMembers] = useState<GenericObject>({});
  const [volunteerAutocomplete, setVolunteerAutocomplete] = useState<
    VolunteerAC[]
  >([]);
  const [initialValues, setInitialValues] = useState<GenericObject>({});
  const [teamData, setTeamData] = useState<GenericObject>({});
  const [showRemoveFromCampaign, setShowRemoveFromCampaign] = useState(false);

  useEffect(() => {
    if (id && isOpen) {
      fetchTeam();
      getVolunteerAutocomplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isOpen]);

  const getVolunteerAutocomplete = async () => {
    const url = `/team_campaigns/volunteer_autocomplete?campaign_id=${campaignId}&team_id=${id}`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return;
    }
    setVolunteerAutocomplete(data);
  };

  const onClose = () => {
    _onClose();
    setLoaded(false);
  };

  const fetchTeam = async () => {
    const url = `/teams/${id}?campaign_id=${campaignId}`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return onClose();
    }
    setInitialValues(data);
    setTeamData(data);
    setLoaded(true);
  };

  const addSelectedMembers = (currValues: any, form: FormApi) => {
    const curr = currValues.volunteers;
    const newIds = {};
    const newVolunteers = tempMembers.map(({ id, name }) => {
      newIds[id] = true;
      return { id, name };
    });
    form.change("volunteers", [...newVolunteers, ...curr]);
    setTempMembers([]);
    setAddedMembers(prev => {
      return { ...prev, ...newIds };
    });
    setRemovedMembers(prev => {
      return Object.fromEntries(
        Object.entries(prev).filter(([k]) => !newIds[k]),
      );
    });
  };

  const getVolunteerOptionTxt = (o: VolunteerAC) => {
    if (!o) return "";
    const { id, nameWithId, onTeam, onAnotherTeam } = o;
    let label = nameWithId;
    if (onTeam && !removedMembers[id]) {
      label = `${nameWithId} - Already a member`;
    } else if (onAnotherTeam) {
      label = `${nameWithId} - Already a member on another team`;
    } else if (addedMembers[id]) label = `${nameWithId} - Just added`;
    return label;
  };

  const removeMember = (fields: any, index: number, id: number) => {
    fields.remove(index);
    setAddedMembers(prev => {
      const { [id]: _rm, ...rest } = prev;
      return rest;
    });
    setRemovedMembers(prev => {
      return { ...prev, [id]: true };
    });
  };

  const onSubmit = async (values: any, _: any, complete: any) => {
    if (!teamData.teamCampaignId) return complete();
    const { team_name, team_slug, team_campaign_goal, volunteers } = values;
    const volunteerIds = volunteers.map(({ id }) => id);
    const update = {
      team_name,
      team_slug,
      team_campaign_goal: Number(team_campaign_goal),
      volunteerIds,
    };
    const url = `/team_campaigns/${teamData.teamCampaignId}`;
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting, form, values }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              {isMobile && (
                <div className={classes.mobileClose}>
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
              )}

              <div className={classes.titleRow}>
                <h1>Manage team</h1>
                <Button
                  variant="text"
                  color="primary"
                  href={teamPage}
                  target="_blank"
                  endIcon={<OpenInNewIcon />}
                >
                  TEAM PAGE
                </Button>
              </div>

              <p className={classes.teamId}>Team ID {id}</p>

              {!loaded && (
                <div className={classes.loadingWrapper}>
                  <CircularLoader show={!loaded} />
                </div>
              )}

              <div className={classes.bodyContainer}>
                {loaded && (
                  <Fragment>
                    <Field
                      component={TextField}
                      name="team_name"
                      label="Team name"
                      validate={requiredField}
                      className={classes.input}
                    />
                    <ChangeListeners form={form} />
                    <div className={classes.inputGroup}>
                      <Field
                        component={TextField}
                        name="team_slug"
                        label="Unique URL"
                        validate={requiredField}
                        className={classes.halfInput}
                      />
                      <Field
                        component={TextField}
                        name="team_campaign_goal"
                        label="Goal"
                        type="text"
                        validate={requiredPositiveInteger}
                        className={classes.halfInput}
                      />
                    </div>
                    <h2 className={classes.memberSubheader}>Team members</h2>
                    <div className={classes.acWrapper}>
                      <Autocomplete
                        multiple
                        options={volunteerAutocomplete}
                        value={tempMembers}
                        disableCloseOnSelect
                        getOptionLabel={o => (o ? o.nameWithId : "")}
                        onChange={(_, values) => setTempMembers(values)}
                        getOptionDisabled={o =>
                          Boolean(
                            (!removedMembers[o.id] && o.onTeam) ||
                              o.onAnotherTeam ||
                              addedMembers[o.id],
                          )
                        }
                        renderOption={(o, { selected }) => (
                          <Fragment>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {getVolunteerOptionTxt(o)}
                          </Fragment>
                        )}
                        className={classes.ac}
                        placeholder="search"
                        renderInput={params => (
                          <MuiTextField
                            {...params}
                            placeholder={`Search for ${volunteerLabel} to add to team`}
                          />
                        )}
                      />
                      <Button
                        color="primary"
                        className={classes.add}
                        disabled={!tempMembers.length}
                        onClick={() => addSelectedMembers(values, form)}
                      >
                        Add
                      </Button>
                    </div>
                    <FieldArray name="volunteers">
                      {({ fields }) => {
                        const length = fields.length ? fields.length : 0;
                        if (isMobile && length < 1) return <></>;
                        return (
                          <div className={classes.members}>
                            <div
                              className={classNames(
                                classes.row,
                                classes.headerRow,
                              )}
                            >
                              <div>ID</div>
                              <div>Name</div>
                              <div />
                            </div>
                            {length < 1 && (
                              <div className={classes.noMembers}>
                                No team members yet. Add team members above.
                              </div>
                            )}
                            {fields.map((_, index) => {
                              const { id, name } = fields.value[index];
                              const notLast = index < length - 1;
                              return (
                                <div
                                  key={index}
                                  className={classNames(
                                    classes.row,
                                    notLast && classes.rowBorder,
                                  )}
                                >
                                  <div>{id}</div>
                                  <div>{name}</div>
                                  <div>
                                    {!isMobile && (
                                      <Button
                                        variant="text"
                                        size="small"
                                        startIcon={<CloseIcon />}
                                        className={classes.remove}
                                        onClick={() =>
                                          removeMember(fields, index, id)
                                        }
                                      >
                                        REMOVE
                                      </Button>
                                    )}
                                    {isMobile && (
                                      <IconButton
                                        className={classes.remove}
                                        onClick={() =>
                                          removeMember(fields, index, id)
                                        }
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }}
                    </FieldArray>
                  </Fragment>
                )}
              </div>

              <div className={classes.bottom}>
                <Button
                  variant="text"
                  disabled={submitting}
                  className={classes.delete}
                  startIcon={<CloseIcon />}
                  onClick={e => {
                    e.stopPropagation();
                    setShowRemoveFromCampaign(true);
                  }}
                >
                  REMOVE FROM CAMPAIGN
                </Button>
                <div className={classes.saveAndCancel}>
                  <Button
                    variant={isMobile ? "contained" : "text"}
                    color={isMobile ? "secondary" : "primary"}
                    disabled={submitting}
                    className={classes.cancel}
                    onClick={e => {
                      e.stopPropagation();
                      onClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={submitting}
                    onClick={e => {
                      e.stopPropagation();
                      form.submit();
                    }}
                  >
                    Save
                    <ButtonSpinner show={submitting} />
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      />
      {showRemoveFromCampaign && (
        <RemoveTeamFromCampaign
          refreshList={refreshList}
          teamData={teamData}
          onClose={() => setShowRemoveFromCampaign(false)}
        />
      )}
    </ResponsiveModal>
  );
}

function ChangeListeners({ form }: { form: FormApi }) {
  return (
    <Fragment>
      <OnChange name="team_name">
        {v => {
          if (v) form.change("team_slug", v.replace(/\s+/g, ""));
        }}
      </OnChange>
      {/* DON"T REMOVE - IF ALL MEMBERS ALL REMOVED `volunteers` WILL BE UNDEFINED IF WE DON'T DO THIS */}
      <OnChange name="volunteers">
        {v => {
          if (!Array.isArray(v)) form.change("volunteers", []);
        }}
      </OnChange>
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  loadingWrapper: {
    minHeight: 300,
    display: "flex",
  },
  form: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  mobileClose: {
    position: "sticky",
    top: 0,
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: 12,
  },
  titleRow: {
    padding: "0 24px",
    minHeight: 64,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      borderBottom: "none",
      padding: "0 16px",
    },
  },
  teamId: {
    padding: "24px 0 0 24px",
    fontSize: 16,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: "8px 16px 10px 16px",
    },
  },
  bodyContainer: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  halfInput: {
    width: "calc(50% - 8px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
  },
  memberSubheader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    marginTop: 6,
    marginBottom: 16,
    color: theme.palette.primary.main,
  },
  acWrapper: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  ac: {
    width: "100%",
  },
  add: {
    width: 90,
    marginLeft: 16,
    borderRadius: 20,
    height: 40,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: 300,
      marginLeft: 0,
      marginTop: 16,
      borderRadius: 18,
      height: 36,
    },
  },
  members: {
    marginTop: 24,
    border: "1px solid #C9CDDE",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      border: "none",
      borderBottom: "1px solid #C9CDDE",
      marginRight: -16,
      marginLeft: -16,
    },
  },
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "88px 1fr 118px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 0 6px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      padding: "10px 0 10px 16px",
      gridTemplateColumns: "88px 1fr 46px",
      "& > *": {
        paddingRight: 10,
      },
    },
  },
  rowBorder: {
    borderBottom: "1px solid #C9CDDE",
  },
  headerRow: {
    borderBottom: "1px solid #C9CDDE",
    minHeight: 52,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  remove: {
    color: theme.palette.error.main,
  },
  noMembers: {
    minHeight: 52,
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    fontSize: 14,
    letterSpacing: 0.13,
    color: theme.palette.text.secondary2,
  },
  bottom: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 24px",
    position: "sticky",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column-reverse",
      padding: 16,
      paddingBottom: 8,
    },
  },
  saveAndCancel: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      marginBottom: 12,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 16,
      width: 160,
      maxWidth: "calc(50% - 8px)",
    },
  },
  cancel: {
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: 160,
      maxWidth: "calc(50% - 8px)",
      textTransform: "unset",
    },
  },
  delete: {
    color: theme.palette.error.main,
  },
}));
