import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Button, makeStyles } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import {
  isBigKahunaCompany,
  APIUtilClient,
  generateAuthHeader,
  downloadXlsx,
  getUserTimezone,
} from "../../../lib";

export function BigKahunaCustomInvoice({ campaign_id }) {
  const companyId = useSelector(state => state.user.companyId);
  const isBigKahuna = isBigKahunaCompany(companyId);
  const classes = styles();

  const fetchReport = async post_closeout => {
    const timezone = getUserTimezone();
    const res = await APIUtilClient.get(
      `/xlsx_reports/big_kahuna_invoice?campaign_id=${campaign_id}&timezone=${timezone}&post_closeout=${post_closeout}`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json",
          ...generateAuthHeader(),
        },
      },
    );

    downloadXlsx(
      res,
      post_closeout
        ? "Post Closeout School Invoice.XLSX"
        : "School Invoice.XLSX",
    );
  };

  if (!isBigKahuna) return <></>;
  return (
    <Fragment>
      <Button
        onClick={() => fetchReport(false)}
        variant="contained"
        color="primary"
        endIcon={<DownloadIcon />}
        className={classes.button}
        size="small"
      >
        BK invoice
      </Button>
      <Button
        onClick={() => fetchReport(true)}
        variant="contained"
        color="primary"
        endIcon={<DownloadIcon />}
        className={classes.button}
        size="small"
      >
        BK PCO invoice
      </Button>
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  button: { marginLeft: 10 },
}));
